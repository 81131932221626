import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { OfferDetailsShareActionsAdapter } from "../offer-details-share-actions.data";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { debounceTime, startWith } from "rxjs/operators";
import { OfferDetailsShareActionsData } from "../../../offer-details-section.model";

@UntilDestroy()
@Component({
  selector: "ifbp-generate-email-offer.",
  templateUrl: "./generate-email-offer.component.html",
  styleUrls: ["./generate-email-offer.component.scss"],
  providers: [OfferDetailsShareActionsAdapter],
})
export class GenerateEmailOfferComponent implements OnInit {
  form: UntypedFormGroup;
  mailTo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OfferDetailsShareActionsData,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly adapter: OfferDetailsShareActionsAdapter,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: this.data?.contactEmails?.join("; ") ?? "",
    });
    this.form.valueChanges.pipe(untilDestroyed(this), debounceTime(200), startWith(this.form.value))
      .subscribe(data => {
        this.mailTo = this.adapter.prepareMailToAttribute(data?.email, this.data);
      })
  }

  static show(
    dialog: MatDialog,
    data: OfferDetailsShareActionsData,
  ) {
    dialog.open(GenerateEmailOfferComponent, {
      width: "560px",
      data: { ...data },
      closeOnNavigation: true,
    });
  }
}
