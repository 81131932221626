import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import {
  MessageService,
  ResourceService,
} from "common";
import { catchError, map } from "rxjs/operators";
import { defaultObservableErrorHandler } from "projects/partner/src/Tools";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData, SubmissionQueryParams, SubmissionsHintsQueryParams, SubmissionsHintsResponse, SubmissionsQueryResult } from "../domain/models/submissions.model";
import { ApiPaths } from "../../../core/const/api-paths";
import { AccountData, ApplicationData, ApplicationDocumentsQueryResult, ContactQueryResult, ContactsQueryParams, UpdateApplicationPayload } from "../domain/models/application.model";
import { LoanData } from "../domain/models/loan.model";
import { adaptApplicationData, ApplicationResponse } from "./api-models/application-api.model";

@Injectable({
  providedIn: "root",
})
export class SubmissionsRemote {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private resourceService: ResourceService
  ) { }

  updateApplicationStage(
    applicationId: number,
    payload: UpdateApplicationPayload,
  ): Observable<ApplicationData> {
    return this.http.patch<any>(ApiPaths.submission.application(applicationId), payload)
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getSubmissions(params: SubmissionQueryParams): Observable<SubmissionsQueryResult> {
    return this.http.get<SubmissionsQueryResult>(ApiPaths.submission.list, { params: <any>params });
  }

  getSubmissionsHints(params: SubmissionsHintsQueryParams): Observable<string[]> {
    return this.http.get<SubmissionsHintsResponse>(ApiPaths.submission.hints, { params: <any>params })
      .pipe(map(response => (response.values)));
  }

  getApplicationData(id: number): Observable<ApplicationData> {
    return this.http
      .get<ApplicationResponse>(ApiPaths.submission.application(id))
      .pipe(
        catchError(defaultObservableErrorHandler(this.messageService)),
        map(response => adaptApplicationData(response))
      );
  }

  getAccountData(id: number): Observable<AccountData> {
    return this.http
      .get<AccountData>(ApiPaths.submission.applicationAccount(id))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getApplicationDocuments(id: number): Observable<ApplicationDocumentsQueryResult> {
    return this.http
      .get<ApplicationDocumentsQueryResult>(ApiPaths.submission.applicationDocuments(id))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  getContactsData(params: ContactsQueryParams): Observable<ContactQueryResult> {
    return this.http
      .get<ContactQueryResult>(ApiPaths.submission.contacts, { params: <any>params })
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  downloadApplicationDocument(applicationId: number, documentId: number, filename: string): void {
    const url = ApiPaths.submission.applicationDocument(applicationId, documentId)
    return this.resourceService.download(url, filename);
  }

  getLoanData(id: number): Observable<LoanData> {
    return this.http.get<LoanData>(ApiPaths.submission.loanAccount(id));
  }

  sendCheckout(appId: number): Observable<void> {
    return this.http.post<void>(ApiPaths.submission.applicationCreateLoans(appId), {})
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  resendCheckout(appId: number): Observable<void> {
    return this.http.post<void>(ApiPaths.submission.applicationResendCheckout(appId), {})
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  sendBankLink(appId: number, email: string): Observable<void> {
    return this.http.post<void>(ApiPaths.submission.bankLinkingInvitation(appId), { email })
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  removeCheckoutRequirementDocument(data: RemoveCheckoutRequirementDocumentData): Observable<void> {
    return this.http
      .delete<void>(ApiPaths.submission.checkoutRequirementFile(data.checkoutRequirementId, data.documentId))
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }

  downloadCheckoutRequirementDocument(data: DownloadCheckoutRequirementDocumentData): void {
    const url = ApiPaths.submission.checkoutRequirementFile(data.checkoutRequirementId, data.documentId)
    return this.resourceService.download(url, data.filename);
  }

  saveCheckoutRequirementDocument(data: SaveCheckoutRequirementDocumentData): Observable<void> {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("type", data.type);
    formData.append("file", data.data);
    return this.http
      .post<void>(ApiPaths.submission.checkoutRequirementFileCategory(data.checkoutRequirementId, data.category), formData)
      .pipe(catchError(defaultObservableErrorHandler(this.messageService)));
  }
}
