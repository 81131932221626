import { Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SubmissionDetailsActionsService } from "./submission-details-actions.service";
import { ApplicationDocumentData } from "../../../domain/models/application.model";
import { SubmissionsFacade } from "../../../domain/+state/submissions.facade";
import { NotInterestedActionCompletedData } from "../../not-interested-action/not-interested-action.model";
import { AppPageService } from "common";

@UntilDestroy()
@Injectable()
export class SubmissionDetailsHandleSharedActionsService {
  constructor(
    private readonly actionsService: SubmissionDetailsActionsService,
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly appPageService: AppPageService,
  ) { }

  watchActions(): void {
    this.actionsService.close$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onClose());

    this.actionsService.notInterestedActionComplete$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onNtInterestedActionComplete(data));

    this.actionsService.downloadApplicationDocument$
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.onDownloadApplicationDocument(data));
  }

  private onClose() {
    this.appPageService.back();
  }

  private onDownloadApplicationDocument(data: ApplicationDocumentData) {
    return this.submissionsFacade.downloadApplicationDocument(data.applicationId, data.id, data.filename);
  }

  private onNtInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.appPageService.back();
    }
  }
}
