import { DocumentCategory } from "common";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";

export interface OfferRequirementsSectionData {
  brokerStatus: BrokerStatus;
  isDataValid: boolean;
  requirements?: OfferRequirementsData;
}

export interface OfferRequirementsData {
  closingDocuments: OfferRequirementData[];
  nextSteps: OfferRequirementData[];
}

export interface OfferRequirementData {
  requirementId: number;
  indicatorType: OfferRequirementsIndicatorType;
  name: string;
  documents?: OfferRequirementDocumentData[];
  description?: string;
  isUploadActionAvailable?: boolean;
  maxDocumentsCountToUpload?: number;
  category?: DocumentCategory;
  isValid: boolean;
}

export interface OfferRequirementDocumentData {
  id: number;
  name: string;
  isRemovable: boolean;
  isDownloadable: boolean;
  isLabelVisible: boolean;
}

export enum OfferRequirementsIndicatorType {
  Met = "met",
  InProgress = "inProgress",
  Incomplete = "incomplete"
}
