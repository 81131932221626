import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { NotInterestedActionCompletedData } from "../../not-interested-action/not-interested-action.model";
import { ApplicationDocumentData } from "../../../domain/models/application.model";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData } from "../../../domain/models/submissions.model";

@Injectable()
export class SubmissionDetailsActionsService {
  private readonly markDataAsLoadedSubject: Subject<boolean> = new Subject();
  markDataAsLoaded$: Observable<boolean> = this.markDataAsLoadedSubject.asObservable();
  markDataAsLoaded = (isDataLoaded: boolean) => this.markDataAsLoadedSubject.next(isDataLoaded);

  private readonly markClosingDocumentsSectionAsInvalidSubject: Subject<boolean> = new Subject();
  markClosingDocumentsSectionAsInvalid$: Observable<boolean> = this.markClosingDocumentsSectionAsInvalidSubject.asObservable();
  markClosingDocumentsSectionAsInvalid = (isInvalid: boolean) => this.markClosingDocumentsSectionAsInvalidSubject.next(isInvalid);

  private readonly closeSubject: Subject<void> = new Subject();
  close$: Observable<void> = this.closeSubject.asObservable();
  close = () => this.closeSubject.next();

  private readonly notInterestedActionCompleteSubject: Subject<NotInterestedActionCompletedData> = new Subject();
  notInterestedActionComplete$: Observable<NotInterestedActionCompletedData> = this.notInterestedActionCompleteSubject.asObservable();
  notInterestedActionComplete = (data: NotInterestedActionCompletedData) => this.notInterestedActionCompleteSubject.next(data);

  private readonly downloadApplicationDocumentSubject: Subject<ApplicationDocumentData> = new Subject();
  downloadApplicationDocument$: Observable<ApplicationDocumentData> = this.downloadApplicationDocumentSubject.asObservable();
  downloadApplicationDocument = (data: ApplicationDocumentData) => this.downloadApplicationDocumentSubject.next(data);

  private readonly removeCheckoutRequirementDocumentSubject: Subject<RemoveCheckoutRequirementDocumentData> = new Subject();
  removeCheckoutRequirementDocument$: Observable<RemoveCheckoutRequirementDocumentData> = this.removeCheckoutRequirementDocumentSubject.asObservable();
  removeCheckoutRequirementDocument = (data: RemoveCheckoutRequirementDocumentData) => this.removeCheckoutRequirementDocumentSubject.next(data);

  private readonly downloadCheckoutRequirementDocumentSubject: Subject<DownloadCheckoutRequirementDocumentData> = new Subject();
  downloadCheckoutRequirementDocument$: Observable<DownloadCheckoutRequirementDocumentData> = this.downloadCheckoutRequirementDocumentSubject.asObservable();
  downloadCheckoutRequirementDocument = (data: DownloadCheckoutRequirementDocumentData) => this.downloadCheckoutRequirementDocumentSubject.next(data);

  private readonly saveCheckoutRequirementDocumentsSubject: Subject<SaveCheckoutRequirementDocumentData[]> = new Subject();
  saveCheckoutRequirementDocuments$: Observable<SaveCheckoutRequirementDocumentData[]> = this.saveCheckoutRequirementDocumentsSubject.asObservable();
  saveCheckoutRequirementDocuments = (data: SaveCheckoutRequirementDocumentData[]) => this.saveCheckoutRequirementDocumentsSubject.next(data);
}
