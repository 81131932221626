import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { debounceTime, distinctUntilChanged, startWith } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { OfferDetailsShareActionsData } from "../../../offer-details-section.model";

@UntilDestroy()
@Component({
  selector: "ifbp-send-bank-link",
  templateUrl: "./send-bank-link.component.html",
  styleUrls: ["./send-bank-link.component.scss"],
})
export class SendBankLinkComponent implements OnInit {
  form: UntypedFormGroup;
  filteredOptions: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OfferDetailsShareActionsData,
    public readonly dialogRef: MatDialogRef<SendBankLinkComponent>,
    private readonly formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: [undefined, [Validators.required, Validators.email]],
    });
    this.form.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.form.value),
        debounceTime(500),
        distinctUntilChanged())
      .subscribe(data => {
        this.filteredOptions = this.filter(data?.email)
      })
  }

  sendBankLinkEmail() {
    if (!this.form.valid) {
      return;
    }

    this.dialogRef.close(this.form.value.email)
  }

  private filter(email?: string): string[] {
    const filterValue = email?.toLowerCase() ?? '';
    return this.data?.contactEmails?.filter(option => option.toLowerCase().includes(filterValue)) ?? [];
  }

  static show(
    dialog: MatDialog,
    data: OfferDetailsShareActionsData,
  ): Observable<string> {
    return dialog.open(SendBankLinkComponent, {
      width: "560px",
      data: { ...data },
      closeOnNavigation: true,
    }).afterClosed();
  }
}
