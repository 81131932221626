import { Injectable } from "@angular/core";
import { CheckoutRequirementDocumentType, CheckoutRequirementStatus, OfferBundleCheckoutRequirement, SimpleDocumentFile } from "common";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { ApplicationData } from "../../../../../domain/models/application.model";
import { OfferRequirementData, OfferRequirementDocumentData, OfferRequirementsData, OfferRequirementsIndicatorType, OfferRequirementsSectionData } from "./offer-requirements-section.model";

const maxCheckoutRequirementDocumentsCount: number = 25;

@Injectable()
export class OfferRequirementsAdapter {
  adaptOfferRequirementsSectionData = (app: ApplicationData, checkoutRequirements: OfferBundleCheckoutRequirement[]): OfferRequirementsSectionData | undefined => {
    if (!checkoutRequirements || checkoutRequirements.length === 0) {
      return undefined;
    }

    const requirements = this.adaptRequirementsData(app.brokerStatus, checkoutRequirements);
    return {
      brokerStatus: app.brokerStatus,
      isDataValid: this.checkIfIsRequirementsDataValid(requirements),
      requirements,
    }
  }

  private adaptRequirementsData(brokerStatus: BrokerStatus, checkoutRequirements: OfferBundleCheckoutRequirement[]): OfferRequirementsData {
    const requirements: OfferRequirementsData = {
      closingDocuments: [],
      nextSteps: [],
    }

    return checkoutRequirements.reduce((acc, item) => {
      if (item.status === CheckoutRequirementStatus.Duplicate || item.status === CheckoutRequirementStatus.Waived) {
        return acc;
      }

      if (item.requiredDocumentType === CheckoutRequirementDocumentType.Document) {
        acc.closingDocuments.push(this.adaptClosingDocumentRequirement(brokerStatus, item));
        return acc;
      }

      acc.nextSteps.push(this.adaptNextStepRequirement(item));
      return acc;

    }, requirements);
  }

  private checkIfIsRequirementsDataValid(requirements: OfferRequirementsData) {
    return requirements.closingDocuments?.every(item => item.isValid) && requirements.nextSteps?.every(item => item.isValid);
  }

  private adaptClosingDocumentRequirement(brokerStatus: BrokerStatus, requirement: OfferBundleCheckoutRequirement): OfferRequirementData {
    const documents = this.adaptOfferRequirementDocumentData(requirement);
    const maxDocumentsCountToUpload = this.getMaxDocumentsCountToUpload(documents?.length);
    return {
      requirementId: requirement.id,
      indicatorType: this.getIndicatorType(requirement),
      name: `${requirement.requiredDocument} ${requirement.status === CheckoutRequirementStatus.Required ? "(Required)" : ""}`,
      maxDocumentsCountToUpload,
      documents,
      description: this.getRequirementDescription(brokerStatus, requirement, maxDocumentsCountToUpload),
      isUploadActionAvailable: this.checkIfIsUploadActionAvailable(brokerStatus, requirement, maxDocumentsCountToUpload),
      category: requirement.category,
      isValid: this.checkIfIsRequirementValid(requirement, documents?.length)
    }
  }

  private adaptNextStepRequirement(requirement: OfferBundleCheckoutRequirement): OfferRequirementData {
    return {
      requirementId: requirement.id,
      indicatorType: OfferRequirementsIndicatorType.Incomplete,
      name: requirement.requiredDocument,
      isValid: true
    }
  }

  private adaptOfferRequirementDocumentData(requirement: OfferBundleCheckoutRequirement): OfferRequirementDocumentData[] {
    return requirement?.documents?.map((item: SimpleDocumentFile) => {
      return {
        id: item.id,
        name: item.filename,
        isRemovable: item.isTemporary,
        isDownloadable: true,
        isLabelVisible: item.isTemporary,
      }
    })
  }

  private checkIfIsUploadActionAvailable(brokerStatus: BrokerStatus, requirement: OfferBundleCheckoutRequirement, maxDocumentsCountToUpload: number): boolean {
    switch (brokerStatus) {
      case BrokerStatus.CONDITIONAL_OFFER:
      case BrokerStatus.OFFER:
      case BrokerStatus.CLOSING_INCOMPLETE:
        return requirement.status !== CheckoutRequirementStatus.Approved && maxDocumentsCountToUpload > 0;
      default:
        return false;
    }
  }

  private getIndicatorType(requirement: OfferBundleCheckoutRequirement): OfferRequirementsIndicatorType {
    if (requirement.status === CheckoutRequirementStatus.Approved) {
      return OfferRequirementsIndicatorType.Met;
    }

    if (requirement.isFileUploaded || requirement.documents?.length > 0) {
      return OfferRequirementsIndicatorType.InProgress;
    }

    return OfferRequirementsIndicatorType.Incomplete;
  }

  private getRequirementDescription(brokerStatus: BrokerStatus, requirement: OfferBundleCheckoutRequirement, maxDocumentsCountToUpload: number): string {
    const additionalNotes = requirement.notes ? `Additional notes: ${requirement.notes}` : "";
    if (requirement.status === CheckoutRequirementStatus.Approved) {
      return additionalNotes;
    }

    if (requirement.isFileUploaded && !requirement.documents?.length) {
      return `Received via alternative method outside Broker Portal. ${additionalNotes}`;
    }

    const isUploadAvailable = this.checkIfIsUploadActionAvailable(brokerStatus, requirement, maxDocumentsCountToUpload);
    return isUploadAvailable ? `Upload any PNG, JPEG, JPG, PDF copies. ${additionalNotes}` : additionalNotes;
  }

  private getMaxDocumentsCountToUpload(currentDocumentsLength?: number): number {
    const max = maxCheckoutRequirementDocumentsCount - (currentDocumentsLength ?? 0);
    return max > 0 ? max : 0;
  }

  private checkIfIsRequirementValid(requirement: OfferBundleCheckoutRequirement, documentsLength?: number): boolean {
    if (requirement.status !== CheckoutRequirementStatus.Required
      || requirement.isFileUploaded
      || documentsLength && documentsLength > 0
    ) {
      return true;
    }
    return false;
  }
}
