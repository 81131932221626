<button mat-icon-button
        [matMenuTriggerFor]="actionsMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item
          (click)="onClickGenerateEmailOffer()">
    <mat-icon>send</mat-icon>
    <span>Generate email offer</span>
  </button>
  <button mat-menu-item
          (click)="onClickCopyOfferLink()">
    <mat-icon>content_copy</mat-icon>
    <span>Copy offer link</span>
  </button>
  <button mat-menu-item
          (click)="onClickSeeCustomerOfferView()">
    <mat-icon>preview</mat-icon>
    <span>See customer offer view</span>
  </button>
  <button mat-menu-item
          (click)="onClickSendBankLink()">
    <mat-icon>account_balance</mat-icon>
    <span>Send bank link</span>
  </button>
</mat-menu>
