<ng-container *ngIf="isDataAvailable">
  <section class="container">
    <header class="header">
      <ng-container [ngSwitch]="data.brokerStatus">
        <ng-container *ngSwitchCase="BrokerStatus.CLOSING">
          <h2 class="heading">
            <mat-icon class="heading__icon"
                      fontSet="material-icons-round">file_present</mat-icon>
            <span class="heading__text">Uploaded</span>
          </h2>
        </ng-container>
        <ng-container *ngSwitchCase="BrokerStatus.CLOSING_INCOMPLETE">
          <h2 class="heading">
            <span class="heading__icon">2</span>
            <span class="heading__text">Upload documents</span>
          </h2>
          <p class="subheading">
            Upload the required closing documents and submit the selected offer for review. <span class="subheading__highlighted">Note that our team will only
              be able to access the files for review after they are submitted.</span>
          </p>
        </ng-container>
      </ng-container>
    </header>
    <section *ngIf="isClosingDocsRequirementsAvailable"
             class="section"
             [ngClass]="{'section--error': isClosingDocsRequirementsMarkedAsInvalid}">
      <header class="section-header">
        <h2 class="section-heading">Closing Documents</h2>
      </header>
      <div class="requirements">
        <ng-container *ngFor="let requirement of data.requirements.closingDocuments; last as isLast">
          <ifbp-offer-requirement-panel [requirement]="requirement">
          </ifbp-offer-requirement-panel>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </div>
    </section>
    <p *ngIf="isClosingDocsRequirementsMarkedAsInvalid"
       class="closing-error">
      Upload the required documents
    </p>
    <section *ngIf="isNextStepsRequirementsAvailable"
             class="section section--next-steps">
      <header class="section-header">
        <h2 class="section-heading">Next Step Requirements</h2>
      </header>
      <div class="requirements">
        <ng-container *ngFor="let requirement of data.requirements.nextSteps; last as isLast">
          <ifbp-offer-requirement-panel [requirement]="requirement">
          </ifbp-offer-requirement-panel>
          <mat-divider *ngIf="!isLast"></mat-divider>
        </ng-container>
      </div>
    </section>
  </section>
</ng-container>
