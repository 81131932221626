import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Component, OnInit } from "@angular/core";
import { CurrencyPipe, PercentPipe } from "@angular/common";
import {
  AppBarAction,
  AppBarActionsService,
  AppBarTitleService,
  AppPageService,
  OfferBundleStatus,
  FeeType,
  ApplicationBaseData,
  OfferBundleCheckoutRequirementService,
  OfferBundleCheckoutRequirement,
  CheckoutRequirementStatus,
  ApplicationStage,
  ProductCode,
  MessageService,
} from "common";
import { ActivatedRoute, Event, NavigationEnd, Router } from "@angular/router";
import {
  fieldSorter,
  hasValue,
  getUppyInputFromFile,
  filterAndSplitCheckoutRequirements,
  convertToExistingFile,
} from "../../Tools";
import { SubmissionService } from "../shared/submission.service";
import {
  ApplicationRequestData,
  BusinessInformationGetData,
  ErrorResponse,
  UppyInput,
} from "../submission/models/submission.model";
import { finalize, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { forkJoin, Observable, of } from "rxjs";
import {
  BrokerStatus,
  BrokerStatusLabel
} from "../shared/SharedConstants";
import {
  AmountToTermMappingEntry,
  CheckoutRequirementsData,
  SliderSetMappingType,
  SliderSets,
  SliderStepData,
  SliderType,
  TermToAmountMappingEntry,
  ValidOfferBundle,
} from "./offer-details.model";
import { OfferCalculator } from "./calculators/offer-calculator";
import { LocOfferCalculator } from "./calculators/loc-offer-calculator";
import { CalculatorService } from "./calculators/calculator.service";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  DEFAULT_SLIDER_OPTIONS,
  MAX_FILE_COUNT,
} from "./offer-details.data";
import { UppyFile } from "@uppy/core";
import { SelectionSubmittedDialogComponent } from "./selection-submitted-dialog/selection-submitted-dialog.component";
import { TermOfferCalculator } from "./calculators/term-offer-calculator";
import { NotInterestedActionCompletedData, NotInterestedActionInputData } from "../feature-modules/submissions/features/not-interested-action/not-interested-action.model";
import { UploadFileDialogImprovedComponent } from "../shared/upload-file-dialog-improved/upload-file-dialog-improved.component";

@UntilDestroy()
@Component({
  selector: "ifbp-offer-details",
  templateUrl: "./offer-details.component.html",
  styleUrls: ["./offer-details.component.scss"],
})
export class OfferDetailsComponent implements OnInit {
  submitTouched: boolean;
  highlightedFieldsRequiredToSubmit: boolean;
  applicationId: number;
  businessInformation: BusinessInformationGetData;
  closingDocumentsForm: UntypedFormGroup;
  currentOfferBundle: ValidOfferBundle;
  currentOfferBundleIndex: number;
  currentCheckoutRequirements: OfferBundleCheckoutRequirement[];
  currentNextStepRequirements: OfferBundleCheckoutRequirement[];
  currentLoanType: ProductCode;
  isCurrentClosingDocumentsValid: boolean;
  currentOfferBundleList: ValidOfferBundle[];
  locOfferBundleList: ValidOfferBundle[];
  termOfferBundleList: ValidOfferBundle[];
  offerBundleCheckoutRequirementsMap: CheckoutRequirementsData = {};
  offerBundleNextStepRequirementsMap: CheckoutRequirementsData = {};
  offerCalculator: OfferCalculator;

  sliderSets: SliderSets;
  notInterestedActionData: NotInterestedActionInputData;

  readonly ProductCode = ProductCode;
  readonly FeeType = FeeType;
  readonly SliderType = SliderType;
  readonly CheckoutRequirementStatus = CheckoutRequirementStatus;
  readonly OfferBundleStatus = OfferBundleStatus;

  constructor(
    private appBarTitleService: AppBarTitleService,
    private appBarActionsService: AppBarActionsService,
    private appPageService: AppPageService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private offerBundleCheckoutRequirementService: OfferBundleCheckoutRequirementService,
    private submissionService: SubmissionService,
    protected _calculatorService: CalculatorService,
    private percentPipe: PercentPipe,
    private currencyPipe: CurrencyPipe,
    private messageService: MessageService
  ) {
    this.appBarActionsService.actions = [
      {
        id: "save",
        label: "SAVE",
        buttonType: "button",
      },
      {
        id: "submit",
        label: "SUBMIT",
        buttonType: "button",
        buttonAppearance: "flat",
        buttonColor: "primary",
      },
    ];
  }

  ngOnInit() {
    this.initRouteData();
    this.initAppBarActions();
  }

  public onClosingDocumentClick(closingDocument: UppyInput) {
    const file = new window.Blob([closingDocument.data], { type: closingDocument.data.type });
    const downloadAncher = document.createElement("a");
    downloadAncher.style.display = "none";
    const fileURL = URL.createObjectURL(file);
    downloadAncher.href = fileURL;
    downloadAncher.download = closingDocument.name;
    downloadAncher.click();
  }

  private initRouteData() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.dataInit(this.applicationId)
        .pipe(untilDestroyed(this))
        .subscribe();
      }
    });
    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(params => {
      const applicationId = params.get("id");
      if (hasValue(applicationId)) {
        this.applicationId = parseFloat(applicationId);
        this.dataInit(this.applicationId)
        .pipe(untilDestroyed(this))
        .subscribe();
      }
    });
  }

  private dataInit(applicationId: number): Observable<boolean> {
    return this.submissionService
      .getBusinessInformationData(applicationId)
      .pipe(
        mergeMap((data: BusinessInformationGetData) => {
          this.businessInformation = data;
          this.notInterestedActionData = this.initNotInterestedActionData(data);
          this.appBarTitleService.title = `${data.name} - ${BrokerStatusLabel.get(data.brokerStatus)}`;
          if (!hasValue(this.businessInformation.offerBundles)) {
            console.error("No offers.");
            return of(null);
          }
          const validOfferBundles = this.businessInformation.offerBundles.filter(
            offerBundle => hasValue(offerBundle.offers?.[0].amount) &&
              hasValue(offerBundle.offers?.[0].repaymentTerm) &&
              hasValue(offerBundle.offers?.[0].drawDownFee) &&
              (offerBundle.status === OfferBundleStatus.Accepted ||
                offerBundle.status === OfferBundleStatus.Pending)
          ) as ValidOfferBundle[];
          this.locOfferBundleList = validOfferBundles.filter(
            offerBundle => offerBundle.offers?.[0].productCode === ProductCode.LOC
          );
          this.termOfferBundleList = validOfferBundles.filter(
            offerBundle => offerBundle.offers?.[0].productCode === ProductCode.Term
          );
          this.sliderSets = {
            [ProductCode.LOC]: this.getSliderOptions(this.locOfferBundleList),
            [ProductCode.Term]: this.getSliderOptions(this.termOfferBundleList),
          };
          if (this.termOfferBundleList.findIndex(
            offerBundle => offerBundle.status === OfferBundleStatus.Accepted
          ) !== -1 ||
            this.locOfferBundleList.length === 0) {
            this.setCurrentLoanType(ProductCode.Term);
          } else {
            this.setCurrentLoanType(ProductCode.LOC);
          }
          const checkoutRequirementsData = {};
          validOfferBundles.forEach(bundle => {
            checkoutRequirementsData[bundle.id] =
              this.offerBundleCheckoutRequirementService.getList(bundle.id);
          });
          return forkJoin(checkoutRequirementsData);
        }),
        map((checkoutRequirements: null | CheckoutRequirementsData) => {
          if (!hasValue(checkoutRequirements)) {
            return false;
          }
          Object.keys(checkoutRequirements).forEach(offerBundleId => {
            [
              this.offerBundleCheckoutRequirementsMap[offerBundleId],
              this.offerBundleNextStepRequirementsMap[offerBundleId],
            ] = filterAndSplitCheckoutRequirements(
              checkoutRequirements[offerBundleId]
            );
          });
          this.currentCheckoutRequirements =
            this.offerBundleCheckoutRequirementsMap[this.currentOfferBundle.id];
          this.currentNextStepRequirements =
            this.offerBundleNextStepRequirementsMap[this.currentOfferBundle.id];
          this.initClosingDocuments();
          return true;
        })
      );
  }

  private initNotInterestedActionData(data: BusinessInformationGetData) {
    return {
      applicationId: this.applicationId,
      brokerStatus: data.brokerStatus,
      createOnDate: new Date(data.createdOn),
      previousStage: <ApplicationStage>data?.previousStage
    }
  }

  setCurrentLoanType(newLoanType: ProductCode) {
    if (newLoanType === ProductCode.LOC) {
      this.currentOfferBundleList = this.locOfferBundleList;
    } else {
      this.currentOfferBundleList = this.termOfferBundleList;
    }
    this.currentLoanType = newLoanType;
    const currentAcceptedBundle = this.currentOfferBundleList.findIndex(
      offerBundle => offerBundle.status === OfferBundleStatus.Accepted,
    );
    if (currentAcceptedBundle !== -1) {
      this.setCurrentOffer(currentAcceptedBundle);
    } else if (this.currentOfferBundleList.length > 1) {
      const mapping = this.sliderSets[newLoanType].amountToTermMapping;
      const key = Object.keys(mapping).sort(
        (a, b) => parseFloat(b) - parseFloat(a),
      )[0];
      this.setCurrentOffer(mapping[key][0].index);
    } else {
      this.setCurrentOffer(0);
    }
    this.submitTouched = false;
  }

  private getSliderOptions(offerBundleList: ValidOfferBundle[]) {
    const unsortedSteps = [
      ...offerBundleList.map((offerBundle, index) => ({
        repaymentTerm: offerBundle.offers[0].repaymentTerm,
        amount: offerBundle.offers[0].amount,
        index,
      })),
    ];
    const amountToTermMapping = this.sortAndGetMappings({
      unsortedSteps,
      order: [SliderType.AMOUNT, SliderType.REPAYMENT_TERM],
    });
    const termToAmountMapping = this.sortAndGetMappings({
      unsortedSteps,
      order: [SliderType.REPAYMENT_TERM, SliderType.AMOUNT],
    });
    const loanAmountOptions = {
      ...DEFAULT_SLIDER_OPTIONS,
      stepsArray: Object.keys(amountToTermMapping)
        .sort(function (a, b) {
          return parseFloat(a) > parseFloat(b) ? 1 : -1;
        })
        .map(key => {
          const amount = parseFloat(key);
          return {
            value: amount,
            legend: `${Math.floor(amount / 1000)}K`,
          };
        }),
    };
    const termOptions = {
      ...DEFAULT_SLIDER_OPTIONS,
      stepsArray: Object.keys(termToAmountMapping)
        .sort(function (a, b) {
          return parseFloat(a) > parseFloat(b) ? 1 : -1;
        })
        .map(key => {
          const repaymentTerm = parseFloat(key);
          return {
            value: repaymentTerm,
            legend: `${repaymentTerm}`,
          };
        }),
    };
    return {
      loanAmountOptions,
      termOptions,
      amountToTermMapping,
      termToAmountMapping,
    };
  }

  private sortAndGetMappings({
    unsortedSteps,
    order,
  }: {
    unsortedSteps: SliderStepData[];
    order: [string, string];
  }) {
    const sortedSteps = fieldSorter<SliderStepData>(unsortedSteps, [
      `-${order[0]}`,
      `-${order[1]}`,
      "index",
    ]);
    const mapping = {};
    sortedSteps.forEach(offerBundle => {
      const key = offerBundle[order[0]];
      const newMapping = {
        [order[1]]: offerBundle[order[1]],
        index: offerBundle.index,
      };
      if (Object.keys(mapping).includes(key.toString())) {
        mapping[key].push(newMapping);
      } else {
        mapping[key] = [newMapping];
      }
    });
    return mapping;
  }

  private initClosingDocuments() {
    const documentsData = {};
    Object.keys(this.offerBundleCheckoutRequirementsMap).forEach(
      offerBundleId => {
        for (const checkoutRequirement of this
          .offerBundleCheckoutRequirementsMap[offerBundleId]) {
          if (checkoutRequirement.documents.length === 0) {
            documentsData[`${checkoutRequirement.id}`] = of(null);
          }
          for (const document of checkoutRequirement.documents) {
            documentsData[`${checkoutRequirement.id}-${document.id}`] =
              this.submissionService.getCheckoutRequirementFile(
                checkoutRequirement.id,
                document.id,
              );
          }
        }
      },
    );
    this.closingDocumentsForm = new FormGroup({});
    forkJoin(documentsData).subscribe((data: { [id: string]: Blob | null }) => {
      Object.keys(this.offerBundleCheckoutRequirementsMap).forEach(
        offerBundleId => {
          const offerBundleFormGroup = new FormGroup({});
          for (const checkoutRequirement of this
            .offerBundleCheckoutRequirementsMap[offerBundleId]) {
            const existingCheckoutRequirementDocuments = [];
            for (const document of checkoutRequirement.documents) {
              const blob = data[`${checkoutRequirement.id}-${document.id}`];
              if (hasValue(blob)) {
                existingCheckoutRequirementDocuments.push(
                  getUppyInputFromFile(document, blob),
                );
              }
            }
            offerBundleFormGroup.addControl(
              checkoutRequirement.id,
              new FormControl(
                existingCheckoutRequirementDocuments,
                checkoutRequirement.status ===
                  CheckoutRequirementStatus.Required &&
                  !checkoutRequirement.isFileUploaded
                  ? Validators.required
                  : [],
              ),
            );
          }
          this.closingDocumentsForm.addControl(
            offerBundleId,
            offerBundleFormGroup,
          );
          this.onClosingDocumentsChange();
        },
      );
    });
    this.closingDocumentsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onClosingDocumentsChange());
  }

  private initAppBarActions() {
    this.appBarActionsService.invoking
      .pipe(untilDestroyed(this))
      .subscribe(this.actionDispatch.bind(this));
  }

  private actionDispatch(action: AppBarAction) {
    const actionHandler: (action: AppBarAction) => void =
      this[action.id].bind(this);
    actionHandler(action);
  }

  private onClosingDocumentsChange() {
    const currentClosingDocumentsFormValue =
      this.closingDocumentsForm?.controls[this.currentOfferBundle.id];
    if (
      hasValue(currentClosingDocumentsFormValue as AbstractControl | undefined)
    ) {
      this.isCurrentClosingDocumentsValid =
        this.closingDocumentsForm?.controls[this.currentOfferBundle.id]?.valid;
    } else {
      this.isCurrentClosingDocumentsValid = true;
    }
  }

  createOfferCalculator() {
    const calculator =
      this.currentLoanType === ProductCode.LOC
        ? LocOfferCalculator
        : TermOfferCalculator;
    this.offerCalculator = new calculator(
      {
        ...this.businessInformation,
        createdOn: new Date(this.businessInformation.createdOn),
      } as ApplicationBaseData,
      this.currentOfferBundle.offers[0],
      this.percentPipe,
      this.currencyPipe,
      this._calculatorService,
    );
  }

  onSliderChange(newValue: number, sliderType: SliderType) {
    const mappingType =
      sliderType === SliderType.AMOUNT
        ? SliderSetMappingType.AMOUNT_TO_TERM
        : SliderSetMappingType.TERM_TO_AMOUNT;
    const mapping = this.sliderSets[this.currentLoanType][mappingType];
    const matchingOfferBundles = mapping[newValue];
    const currentOtherSliderValue =
      this.currentOfferBundle.offers[0][sliderType];
    const newIndex = [...matchingOfferBundles].find(
      (offerBundle: AmountToTermMappingEntry | TermToAmountMappingEntry) =>
        offerBundle[sliderType] === currentOtherSliderValue,
    )?.index;
    if (hasValue(newIndex)) {
      this.setCurrentOffer(newIndex);
    } else {
      this.setCurrentOffer(matchingOfferBundles[0].index);
    }
    this.submitTouched = false;
  }

  private setCurrentOffer(index: number) {
    this.currentOfferBundleIndex = index;
    this.currentOfferBundle =
      this.currentOfferBundleList[this.currentOfferBundleIndex];
    this.currentCheckoutRequirements =
      this.offerBundleCheckoutRequirementsMap?.[this.currentOfferBundle.id];
    this.currentNextStepRequirements =
      this.offerBundleNextStepRequirementsMap?.[this.currentOfferBundle.id];
    this.createOfferCalculator();
    this.onClosingDocumentsChange();
  }

  openUploadDialog(checkoutRequirementId: number) {
      const dialogRef = this.openFileDialog(MAX_FILE_COUNT - this.closingDocumentsForm
        .get([this.currentOfferBundle.id, checkoutRequirementId])
        ?.value.length);

    dialogRef.afterClosed().subscribe((uploadedFiles) => {
      if (!uploadedFiles)
        return;

      this.closingDocumentsForm.get([this.currentOfferBundle.id])?.patchValue({
        [checkoutRequirementId]: [...this.closingDocumentsForm.get([this.currentOfferBundle.id, checkoutRequirementId])?.value, ...uploadedFiles],
      });
    });
  }

  private openFileDialog(maxFileCount: number): MatDialogRef<UploadFileDialogImprovedComponent, UppyFile[]> {
    return UploadFileDialogImprovedComponent.open(this.dialog, { maxFileCount });
  }

  removeDocument(checkoutRequirementId: number, documentIndex: number) {
    const currentCheckoutRequirementDocuments = this.closingDocumentsForm.get([
      this.currentOfferBundle.id,
      checkoutRequirementId,
    ])?.value;
    this.closingDocumentsForm.get([this.currentOfferBundle.id])?.patchValue({
      [checkoutRequirementId]: currentCheckoutRequirementDocuments
        .slice(0, documentIndex)
        .concat(currentCheckoutRequirementDocuments.slice(documentIndex + 1)),
    });
  }

  onSelectOfferChange() {
    this.submissionService
      .submitOfferBundle(this.currentOfferBundle.id)
      .pipe(
        finalize(() => {
          this.actionComplete();
        }),
      )
      .subscribe(()=> this.processOfferBundle());
  }

  private processOfferBundle() {
    // "TODO to be changed during refactor, refreshed data should be get from the API"
    this.businessInformation.offerBundles?.filter(bundle=> {
      if (bundle.status == OfferBundleStatus.Accepted)
      {
        bundle.status = OfferBundleStatus.Pending;
      }
    });
    this.currentOfferBundle.status = OfferBundleStatus.Accepted
  }

  private getFilesToSave(): Observable<ErrorResponse>[] {
    const filesToSave: Observable<ErrorResponse>[] = [];
    this.currentCheckoutRequirements?.forEach(checkoutRequirement => {
      this.closingDocumentsForm
        .get([this.currentOfferBundle.id, checkoutRequirement.id])
        ?.value.forEach((file: UppyFile) => {
          if (!file.meta.isExisting) {
            filesToSave.push(
              this.submissionService.saveCheckoutRequirementFile(
                checkoutRequirement.id,
                checkoutRequirement.category,
                file,
              ).pipe(
                tap(fileIds =>
                  this.markUploadedFileAsExisting(checkoutRequirement, file, fileIds)
                )
              ),
            );
          }
        });
    });
    return filesToSave;
  }

  private markUploadedFileAsExisting(checkoutRequirement: OfferBundleCheckoutRequirement, file: UppyFile, fileIds: ErrorResponse) {
    const currentCheckoutRequirementDocuments = this.getFilesWithId(this.closingDocumentsForm.get([
      this.currentOfferBundle.id,
      checkoutRequirement.id,
    ])?.value) as UppyInput[];

    var convertedFile = convertToExistingFile(file as UppyFile, fileIds[0] as number);
    this.closingDocumentsForm.get([this.currentOfferBundle.id])?.patchValue({
      [checkoutRequirement.id]: [...currentCheckoutRequirementDocuments, convertedFile],
    });
  }

  private getFilesWithId(files: UppyInput[]): UppyInput[] {
    return files.filter(file => file.meta.id);
  }

  save() {
    const tasks: Observable<any>[] = [];
    const filesToSave = this.getFilesToSave();

    if (filesToSave.length > 0)
      filesToSave.forEach((fileToSave) => tasks.push(fileToSave));

    if (tasks.length > 0) {
      forkJoin(tasks)
        .pipe(
          switchMap(_ => this.dataInit(this.applicationId)),
          tap({ next: () => this.onSaveSuccess() }),
          finalize(() => {
            this.actionComplete();
          }),
        )
        .subscribe();
    }
  }

  submit() {
    if (this.blockSubmitIfDataRequired()) {
      this.submitTouched = true;
      return;
    }

    this.submitTouched = true;
    const newStage = this.businessInformation.brokerStatus === BrokerStatus.CONDITIONAL_OFFER ? ApplicationStage.Underwriting : ApplicationStage.ClosingReview;
    const filesToSave = this.getFilesToSave();
    if (filesToSave.length > 0) {
      forkJoin(filesToSave)
        .pipe(mergeMap(() => this.submitStage(newStage)))
        .subscribe();
    } else {
      this.submitStage(newStage).subscribe();
    }
  }

  private blockSubmitIfDataRequired() {
    if (!this.isCurrentClosingDocumentsValid || this.currentOfferBundle.status !== OfferBundleStatus.Accepted) {
      if (this.submitTouched) {
        this.highlightFieldsRequiredToSubmit();
      }
      return true;
    }
    return false;
  }

  private submitStage(stage: ApplicationStage): Observable<ApplicationRequestData> {
    return this.submissionService.updateApplicationStage(
      this.applicationId,
      stage,
    ).pipe(
      finalize(() => {
        this.actionComplete();
        this.openSelectionSubmittedDialog();
      }))
  }

  private onSaveSuccess() {
    setTimeout(() => {
      this.messageService.success(
        "Your offer has been successfully saved.",
      );
    });
  }

  private openSelectionSubmittedDialog() {
    const dialog = this.dialog.open(SelectionSubmittedDialogComponent, {
      data: {
        advisorFirstName: this.businessInformation.advisorFirstName,
        advisorEmail: this.businessInformation.advisorEmail,
        advisorPhone: this.businessInformation.advisorPhone,
      },
    });
    dialog.afterClosed().subscribe(() => {
      this.appPageService.back();
    });
  }

  private actionComplete() {
    this.submitTouched = false;
  }

  private highlightFieldsRequiredToSubmit() {
    this.highlightedFieldsRequiredToSubmit = true;
    setTimeout(() => {
      this.highlightedFieldsRequiredToSubmit = false;
    }, 500);
  }

  onNotInterestedActionComplete(data: NotInterestedActionCompletedData) {
    if (data.isSuccess) {
      this.appPageService.back();
    }
  }
}
