import { NgModule } from "@angular/core";
import { OfferDetailsPanelComponent } from "./components/offer-details-panel/offer-details-panel.component";
import { OfferDetailsSectionComponent } from "./offer-details-section.component";
import { OfferDetailsShareActionsComponent } from "./components/offer-details-share-actions/offer-details-share-actions.component";
import { GenerateEmailOfferComponent } from "./components/offer-details-share-actions/email-offer-details/generate-email-offer.component";
import { SendBankLinkComponent } from "./components/offer-details-share-actions/send-bank-link-email/send-bank-link.component";
import { SubmissionDetailsSharedComponentsModule } from "../../components/submission-details-shared-components.module";

@NgModule({
  imports: [
    SubmissionDetailsSharedComponentsModule,
  ],
  declarations: [
    OfferDetailsPanelComponent,
    OfferDetailsSectionComponent,
    OfferDetailsShareActionsComponent,
    GenerateEmailOfferComponent,
    SendBankLinkComponent
  ],
  exports: [
    OfferDetailsSectionComponent,
  ]
})
export class OfferDetailsSectionModule { }
