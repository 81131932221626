import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BrandingService, CompanyBranding, DialogActionComponent, DialogActionData, DomHelper, MessageService } from 'common';
import { SubmissionsFacade } from '../../../../../../../domain/+state/submissions.facade';
import { bankLinkSentDialogData, OfferDetailsShareActionsAdapter } from './offer-details-share-actions.data';
import { GenerateEmailOfferComponent } from './email-offer-details/generate-email-offer.component';
import { SendBankLinkComponent } from './send-bank-link-email/send-bank-link.component';
import { OfferDetailsShareActionsData, OfferDetailsShareActionsInputData } from '../../offer-details-section.model';

@UntilDestroy()
@Component({
  selector: 'ifbp-offer-details-share-actions',
  templateUrl: './offer-details-share-actions.component.html',
  providers: [OfferDetailsShareActionsAdapter]
})
export class OfferDetailsShareActionsComponent implements OnInit {
  data: OfferDetailsShareActionsData;

  @Input()
  inputData: OfferDetailsShareActionsInputData;

  constructor(
    private readonly submissionFacade: SubmissionsFacade,
    private readonly brandingService: BrandingService,
    private readonly adapter: OfferDetailsShareActionsAdapter,
    private readonly dialog: MatDialog,
    private readonly messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.initData();
  }

  private initData() {
    this.brandingService.getBranding$()
      .pipe(untilDestroyed(this))
      .subscribe((branding: CompanyBranding) => {
        this.data = {
          ...this.inputData,
          ...this.adapter.prepareUrls(this.inputData),
          companyName: branding?.dba ?? "",
        }
      });
  }

  onClickGenerateEmailOffer() {
    GenerateEmailOfferComponent.show(this.dialog, this.data);
  }

  onClickSendBankLink() {
    SendBankLinkComponent.show(this.dialog, this.data).subscribe(email => {
      if (!email) {
        return;
      }

      this.submissionFacade.sendBankLink(this.data.appId, email).subscribe(() => {
        const dialogData: DialogActionData = bankLinkSentDialogData(email);
        return DialogActionComponent.show(this.dialog, dialogData);
      })
    })
  }

  onClickCopyOfferLink(): void {
    DomHelper.copy(this.data.previewUrl);
    this.messageService.info('Link copied');
  }

  onClickSeeCustomerOfferView(): void {
    window.open(this.data.previewUrl, "_blank");
  }
}
