import { Component, Input } from '@angular/core';
import { ProductCode, ProductCodeLabel } from 'common';
import { OfferDetailsData } from './offer-details-section.model';

@Component({
  selector: 'ifbp-offer-details-section',
  templateUrl: './offer-details-section.component.html',
  styleUrls: ['./offer-details-section.component.scss']
})
export class OfferDetailsSectionComponent {
  readonly ProductCode = ProductCode;
  readonly ProductCodeLabel = ProductCodeLabel;

  @Input()
  data: OfferDetailsData;
}
