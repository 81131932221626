import { ApplicationOfferBundleData, OfferBundleStatus, StringHelper } from "common";
import { ApplicationData } from "../../domain/models/application.model";
import { AdvisorData } from "../../shared/models/advisor.model";

export const getContactString = (advisorData: AdvisorData): string => {
  if (!advisorData?.fullName || (!advisorData?.email && !advisorData?.phone)) {
    return '';
  }
  const advisorEmailFormat = advisorData?.email ? `<a href="mailto:${advisorData.email}">${advisorData.email}</a>` : '';
  const advisorPhoneFormat = advisorData?.phone ? `<a href="tel:${advisorData.phone}">${StringHelper.phoneFormat(advisorData.phone)}</a>` : '';

  if (!advisorData?.email) {
    return `Contact ${advisorData.fullName} at ${advisorPhoneFormat} if you have any questions.`;
  }

  if (!advisorData?.phone) {
    return `Contact ${advisorData.fullName} at ${advisorEmailFormat} if you have any questions.`;
  }

  return `Contact ${advisorData.fullName} at ${advisorEmailFormat} or ${advisorPhoneFormat} if you have any questions.`;
}

export const getSelectedOfferBundle = (app: ApplicationData): ApplicationOfferBundleData | undefined => {
  return app?.offerBundles?.filter(offerBundle => offerBundle.status === OfferBundleStatus.Accepted)?.at(0);
}
