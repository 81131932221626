<section class="container">
  <ng-container *ngIf="data?.productCode && data?.panelData; else notAvailable">
    <header class="header">
      <h2 class="heading">
        <ng-container [ngSwitch]="data.productCode">
          <ng-container *ngSwitchCase="ProductCode.LOC">
            <mat-icon class="heading__icon heading__icon--loc">data_saver_off</mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="ProductCode.Term">
            <mat-icon class="heading__icon heading__icon--term">assessment</mat-icon>
          </ng-container>
        </ng-container>
        <span class="heading__text">{{data.productCode | enumValueLabel: ProductCode : ProductCodeLabel}}</span>
      </h2>
      <div>
        <ifbp-offer-details-share-actions [inputData]="data.shareActionsData"></ifbp-offer-details-share-actions>
      </div>
    </header>
    <section>
      <mat-accordion>
        <ifbp-offer-details-panel [data]="data?.panelData"></ifbp-offer-details-panel>
      </mat-accordion>
    </section>
  </ng-container>
  <ng-template #notAvailable>
    <span class="not-available">Offer details are not available</span>
  </ng-template>
</section>
