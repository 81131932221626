import { Injectable } from "@angular/core";
import { SubmissionsFacade } from "../../../../domain/+state/submissions.facade";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubmissionDetailsFacade } from "../../submission-details.facade";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { ContractDetailsComponentData } from "./contract-details.model";
import { getContractDetailsAppBarActions, getContractDetailsHeaderData, loanCreatedDialogData } from "./contract-details.data";
import { DialogActionComponent, DialogActionData } from "common";
import { MatDialog } from "@angular/material/dialog";
import { OfferDetailsAdapter } from "../../shared/modules/offer-details-section/offer-details-section.data";

@Injectable()
export class ContractDetailsFacade {
  constructor(
    private readonly detailsFacade: SubmissionDetailsFacade,
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly offerDetailsAdapter: OfferDetailsAdapter,
    private readonly dialog: MatDialog,
  ) { }

  getComponentData$(applicationId: number): Observable<ContractDetailsComponentData> {
    return this.detailsFacade.getComponentBaseData$(applicationId)
      .pipe(map((data: SubmissionDetailsBaseComponentData) => {
        const headerData = getContractDetailsHeaderData(data.application.brokerStatus);
        const offerDetailsData = this.offerDetailsAdapter.adaptOfferDetailsSectionData(data.application);
        const appBarActions = getContractDetailsAppBarActions(data.application.brokerStatus);
        return {
          ...data,
          headerData,
          offerDetailsData,
          appBarActions
        }
      }));
  }


  sendCheckout(appId: number) {
    return this.submissionsFacade.sendCheckout(appId).pipe(switchMap(() => this.openConfirmationDialog()));
  }

  resendCheckout(appId: number) {
    return this.submissionsFacade.resendCheckout(appId).pipe(switchMap(() => this.openConfirmationDialog()));
  }

  private openConfirmationDialog() {
    const dialogData: DialogActionData = loanCreatedDialogData;
    return DialogActionComponent.show(this.dialog, dialogData)
  }
}
