import { Injectable } from "@angular/core";
import { ApplicationOffer, EnumValueLabelPipe, PaymentFrequency, PaymentFrequencyLabel, ProductCode } from "common";
import { CurrencyPipe, DecimalPipe, PercentPipe } from "@angular/common";
import { LocOfferCalculator } from 'projects/partner/src/app/offer-details/calculators/loc-offer-calculator';
import { CalculatorService } from 'projects/partner/src/app/offer-details/calculators/calculator.service';
import { TermOfferCalculator } from 'projects/partner/src/app/offer-details/calculators/term-offer-calculator';
import { OfferCalculator } from 'projects/partner/src/app/offer-details/calculators/offer-calculator';
import { getSelectedOfferBundle } from "../../../submission-details.data";
import { ApplicationData } from "../../../../../domain/models/application.model";
import { OfferDetailsData, OfferDetailsPanelData, OfferDetailsShareActionsInputData } from "./offer-details-section.model";
import { LabelValueData } from "../../components/submission-details-basic-section/submission-details-basic-section.model";

@Injectable()
export class OfferDetailsAdapter {
  constructor(
    private readonly enumValueLabelPipe: EnumValueLabelPipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly percentPipe: PercentPipe,
    private readonly decimalPipe: DecimalPipe,
    private readonly calculator: CalculatorService,
  ) { }

  adaptOfferDetailsSectionData = (app: ApplicationData): OfferDetailsData | undefined => {
    const selectedOfferBundle = getSelectedOfferBundle(app);
    const selectedOffer: ApplicationOffer | undefined = selectedOfferBundle?.offers?.at(0);

    if (!selectedOffer) {
      return undefined;
    }

    return {
      productCode: selectedOffer.productCode,
      panelData: this.preparePanelData(app, selectedOffer),
      shareActionsData: this.prepareShareActionsData(app, selectedOffer, selectedOfferBundle?.id),
    }
  }

  private prepareShareActionsData(app: ApplicationData, selectedOffer: ApplicationOffer, offerBundleId?: number): OfferDetailsShareActionsInputData {
    return {
      appId: <number>app.id,
      appUuid: <string>app.uuid,
      offerBundleId: <number>offerBundleId,
      accountName: <string>app.accountName,
      productCode: <ProductCode>selectedOffer.productCode,
      offerAmount: <number>selectedOffer.amount,
      contactEmails: app.contactEmails,
    }
  }

  private preparePanelData(app: ApplicationData, selectedOffer: ApplicationOffer) {
    switch (selectedOffer.productCode) {
      case ProductCode.LOC:
        return this.adaptLocPanelData(app, selectedOffer);
      case ProductCode.Term:
        return this.adaptTermPanelData(app, selectedOffer);
      default:
        return undefined;
    }
  }

  private adaptLocPanelData(app: ApplicationData, selectedOffer: ApplicationOffer): OfferDetailsPanelData {
    const calc = new LocOfferCalculator(app, selectedOffer, this.percentPipe, this.currencyPipe, this.calculator);

    return {
      headerData: this.prepareHeaderData(calc),
      rows: [
        { label: 'Credit limit', value: this.getFormattedAmount(calc) },
        ...this.adaptCommonRows(calc),
        { label: 'Daily finance charges', value: <string>this.percentPipe.transform(calc?.dailyInterestRate, "1.4-4") },
        { label: 'Monthly finance cost*', value: <string>this.percentPipe.transform(calc?.monthlyRate, "1.2-2") },
        { label: 'Annual finance cost*', value: <string>this.percentPipe.transform(calc?.annualRate, "1.2-2") },
        {
          label: `Commissions (${<string>this.percentPipe.transform(calc?.brokerInitialDrawCommision, "1.2-2")})`,
          value: <string>this.currencyPipe.transform(calc?.getCommision(), 'USD', 'symbol', '1.2-2')
        }
      ],
      footerText: this.prepareFooterText(selectedOffer?.productCode)
    }
  }

  private prepareFooterText(productCode?: ProductCode): string {
    if (productCode === ProductCode.LOC) {
      return "* The cost of finance is calculated based on the finance charges paid over the life of the loan, assuming an organic repayment."
    }
    return "";
  }

  private adaptTermPanelData(app: ApplicationData, selectedOffer: ApplicationOffer): OfferDetailsPanelData {
    const calc = new TermOfferCalculator(app, selectedOffer, this.percentPipe, this.currencyPipe, this.calculator);

    return {
      headerData: this.prepareHeaderData(calc),
      rows: [
        { label: 'Loan amount', value: this.getFormattedAmount(calc), },
        ...this.adaptCommonRows(calc),
        { label: 'Factor rate', value: <string>this.decimalPipe.transform(calc?.rate / 100 + 1, "1.4-4") },
        {
          label: `Commissions (${<string>this.percentPipe.transform(<number>calc?.applicationOffer?.maxUpsell / 100, "1.2-2")})`,
          value: <string>this.currencyPipe.transform(calc?.getCommision(), 'USD', 'symbol', '1.2-2')
        }
      ]
    }
  }

  private adaptCommonRows(calc: OfferCalculator): LabelValueData[] {
    const formattedPaymentFrequency = this.getFormattedPaymentFrequency(calc);
    return [
      { label: 'Term', value: this.getFormattedTerm(calc) },
      { label: `Fee (${calc?.drawFeeFormatted})`, value: <string>this.currencyPipe.transform(calc?.uiFee) },
      { label: 'Disbursed amount', value: <string>this.currencyPipe.transform(calc?.disbursedAmount, 'USD', 'symbol', '1.2-2') },
      { label: `${formattedPaymentFrequency} payment`, value: <string>this.currencyPipe.transform(calc?.termPayment) },
      { label: 'Finance charges paid', value: <string>this.currencyPipe.transform(calc?.sampleDuration?.interest) },
      { label: 'Payback', value: <string>this.currencyPipe.transform(calc?.sampleDuration?.payback) },
    ]
  }

  private prepareHeaderData(calc: OfferCalculator) {
    return {
      formattedAmount: this.getFormattedAmount(calc),
      formattedTerm: this.getFormattedTerm(calc),
      formattedPaymentFrequency: `${this.getFormattedPaymentFrequency(calc)} payments`
    }
  }

  private getFormattedAmount(calc: OfferCalculator): string {
    return <string>this.currencyPipe.transform(calc?.amount);
  }

  private getFormattedTerm(calc: OfferCalculator) {
    return `${calc?.term} ${calc?.term > 1 ? "months" : "month"}`;
  }

  private getFormattedPaymentFrequency(calc: OfferCalculator) {
    return this.enumValueLabelPipe.transform(calc?.paymentFrequency, PaymentFrequency, PaymentFrequencyLabel);
  }
}
