import { Injectable } from "@angular/core";
import { ButtonColor, DialogActionData, EnumValueLabelPipe, ProductCode, ProductCodeLabel } from "common";
import { CurrencyPipe } from "@angular/common";
import { AppSettings } from "projects/partner/src/app.settings";
import { Router } from "@angular/router";
import { OfferDetailsShareActionsData, OfferDetailsShareActionsInputData, OfferDetailsShareActionsUrlsData } from "../../offer-details-section.model";

@Injectable()
export class OfferDetailsShareActionsAdapter {
  constructor(
    private readonly enumValueLabelPipe: EnumValueLabelPipe,
    private readonly currencyPipe: CurrencyPipe,
    private readonly appSettings: AppSettings,
    private readonly router: Router,
  ) { }

  prepareMailToAttribute(emailTo: string, data: OfferDetailsShareActionsData): string {
    if (!data) {
      return "";
    }

    const productName = this.enumValueLabelPipe.transform(data.productCode, ProductCode, ProductCodeLabel);
    const formattedAmount = this.currencyPipe.transform(data.offerAmount, "USD", "symbol", "1.2-2");
    const previewUrl = `%0D%0A${encodeURIComponent(data.previewUrl)}`
    const emailBody = `Congratulations! ${data.accountName} has been approved for a ${productName} of ${formattedAmount} from ${data.companyName}. See the offer details here:${previewUrl}`;

    return `mailTo:${emailTo}?Subject=Your ${data.companyName} ${productName} Offer&body=${emailBody};`
  }

  prepareUrls(data: OfferDetailsShareActionsInputData): OfferDetailsShareActionsUrlsData {
    const appRootUrl = this.appSettings.projectsRootSiteUrls.application;
    const summaryPath = this.router.createUrlTree(["summary"], { queryParams: { uuid: data.appUuid, bundleId: data.offerBundleId } }).toString();

    return {
      previewUrl: `${appRootUrl}/preview${summaryPath}`,
      calculatorUrl: `${appRootUrl}/calculator${summaryPath}`,
    }
  }
}

export const bankLinkSentDialogData = (email: string): DialogActionData => {
  return {
    body: `Bank link was successfully sent to ${email}.`,
    title: "Bank link sent",
    buttons: [
      {
        label: "OK",
        buttonColor: ButtonColor.Primary,
        action: true,
      }
    ]
  }
}
