<ng-container *ngIf="data">
  <mat-expansion-panel class="panel-container"
                       hideToggle>
    <mat-expansion-panel-header class="panel-header">
      <div class="header-container">
        <div class="header">
          <span>{{data.headerData?.formattedAmount}}</span>
          <span>{{data.headerData?.formattedTerm}}</span>
          <span>{{data.headerData?.formattedPaymentFrequency}}</span>
        </div>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </mat-expansion-panel-header>
    <div class="content">
      <ifbp-submission-details-basic-section [rows]="data?.rows"
                                             [displayMode]="DisplayMode.Narrow">
      </ifbp-submission-details-basic-section>
      <p *ngIf="data.footerText"
         class="footerText">
        {{data.footerText}}
      </p>
    </div>
  </mat-expansion-panel>
</ng-container>
