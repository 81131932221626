import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SubmissionsFacade } from "../../../../domain/+state/submissions.facade";
import { SubmissionDetailsFacade } from "../../submission-details.facade";
import { SubmissionDetailsBaseComponentData } from "../../submission-details.model";
import { getSelectedOfferBundle } from "../../submission-details.data";
import { getClosingDetailsAppBarActions, getClosingDetailsHeaderData, getIncompleteSubmissionDialogData, getSubmissionDetailsBannerData, getSubmitConfirmationDialogData } from "./closing-details.data";
import { ClosingDetailsComponentData } from "./closing-details.model";
import { ApplicationStage, DialogActionComponent, DialogActionData, OfferBundleCheckoutRequirement } from "common";
import { MatDialog } from "@angular/material/dialog";
import { ApplicationData } from "../../../../domain/models/application.model";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData } from "../../../../domain/models/submissions.model";
import { OfferRequirementsAdapter } from "../../shared/modules/offer-requirements-section/offer-requirements-section.data";
import { OfferDetailsAdapter } from "../../shared/modules/offer-details-section/offer-details-section.data";

@Injectable()
export class ClosingDetailsFacade {
  constructor(
    private readonly detailsFacade: SubmissionDetailsFacade,
    private readonly submissionsFacade: SubmissionsFacade,
    private readonly offerRequirementsAdapter: OfferRequirementsAdapter,
    private readonly offerDetailsAdapter: OfferDetailsAdapter,
    private readonly dialog: MatDialog,
  ) { }

  getComponentData$(applicationId: number): Observable<ClosingDetailsComponentData> {
    return this.detailsFacade.getComponentBaseData$(applicationId)
      .pipe(switchMap((data: SubmissionDetailsBaseComponentData) => {
        return this.getClosingDetailsComponentData$(data);
      }));
  }

  private getClosingDetailsComponentData$(data: SubmissionDetailsBaseComponentData): Observable<ClosingDetailsComponentData> {
    return this.submissionsFacade.getAdvisorData$(data.application.id)
      .pipe(switchMap((advisorData: AdvisorData) => {
        const selectedOfferBundleId = getSelectedOfferBundle(data.application)?.id;
        const viewData = {
          ...data,
          headerData: getClosingDetailsHeaderData(data.application.brokerStatus, advisorData),
          advisorData,
          offerDetailsData: this.offerDetailsAdapter.adaptOfferDetailsSectionData(data.application),
          bannerData: getSubmissionDetailsBannerData(data.application),
          appBarActions: getClosingDetailsAppBarActions(data.application.brokerStatus),
        }

        if (!selectedOfferBundleId) {
          return of(viewData);
        }
        return this.submissionsFacade.getCheckoutRequirements$(selectedOfferBundleId)
          .pipe(map((checkoutRequirements: OfferBundleCheckoutRequirement[]) => ({
            ...viewData,
            selectedOfferBundleId: <number>selectedOfferBundleId,
            offerRequirementsData: this.offerRequirementsAdapter.adaptOfferRequirementsSectionData(data.application, checkoutRequirements),
          })))
      }))
  }

  openIncompleteSubmissionDialogIfNeeded(application: ApplicationData) {
    if (application.brokerStatus !== BrokerStatus.CLOSING_INCOMPLETE) {
      return;
    }

    const dialogData: DialogActionData = getIncompleteSubmissionDialogData(application.incompleteInfo);
    return DialogActionComponent.show(this.dialog, dialogData)
  }

  removeCheckoutRequirementDocument(offerBundleId: number, data: RemoveCheckoutRequirementDocumentData) {
    this.submissionsFacade.removeCheckoutRequirementDocument(offerBundleId, data).subscribe();
  }

  downloadCheckoutRequirementDocument(data: DownloadCheckoutRequirementDocumentData): void {
    this.submissionsFacade.downloadCheckoutRequirementDocument(data);
  }

  saveCheckoutRequirementDocuments(offerBundleId: number, documentsToSave: SaveCheckoutRequirementDocumentData[]): void {
    this.submissionsFacade.saveCheckoutRequirementDocuments(offerBundleId, documentsToSave).subscribe();
  }

  submitForReview(appId: number, advisorData: AdvisorData): Observable<boolean> {
    return this.submissionsFacade.updateApplicationStage(appId, { stage: ApplicationStage.ClosingReview })
      .pipe(switchMap(() => this.openConfirmationDialog(advisorData)));
  }

  private openConfirmationDialog(advisorData: AdvisorData) {
    const dialogData: DialogActionData = getSubmitConfirmationDialogData(advisorData);
    return DialogActionComponent.show(this.dialog, dialogData)
  }
}
