import { Component, Input } from '@angular/core';
import { OfferDetailsPanelData } from '../../offer-details-section.model';
import { SubmissionDetailsBasicSectionDisplayMode } from '../../../../components/submission-details-basic-section/submission-details-basic-section.model';

@Component({
  selector: 'ifbp-offer-details-panel',
  templateUrl: './offer-details-panel.component.html',
  styleUrls: ['./offer-details-panel.component.scss']
})
export class OfferDetailsPanelComponent {
  readonly DisplayMode = SubmissionDetailsBasicSectionDisplayMode;

  @Input()
  data: OfferDetailsPanelData;
}
