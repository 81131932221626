import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { OfferBundleCheckoutRequirement, StateService } from "common";
import { ApplicationData } from "../models/application.model";

interface OfferBundleCheckoutRequirementState {
  offerBundleId: number;
  checkoutRequirements: OfferBundleCheckoutRequirement[];
}

export interface SubmissionsState {
  application: ApplicationData;
  checkoutRequirementsState: OfferBundleCheckoutRequirementState;
}

const initialState: SubmissionsState = null;

@Injectable({
  providedIn: "root",
})
export class SubmissionsStateService extends StateService<SubmissionsState> {
  constructor() {
    super(initialState);
  }

  getApplicationData$(): Observable<ApplicationData> {
    return this.select((state: SubmissionsState) => state?.application);
  }

  getCheckoutRequirementsData$(): Observable<OfferBundleCheckoutRequirement[]> {
    return this.select((state: SubmissionsState) => state?.checkoutRequirementsState?.checkoutRequirements);
  }

  setApplicationData(application: ApplicationData): void {
    const newState = {
      ...this.state,
      application,
    };
    this.setState(newState);
  }

  setCheckoutRequirementsData(offerBundleId: number, checkoutRequirements: OfferBundleCheckoutRequirement[]): void {
    const newState: SubmissionsState = {
      ...this.state,
      checkoutRequirementsState: {
        offerBundleId,
        checkoutRequirements,
      },
    };
    this.setState(newState);
  }

  isApplicationDataLoaded(id: number): boolean {
    return !!this.state?.application && this.state.application.id === id;
  }

  isCheckoutRequirementsDataLoaded(offerBundleId: number): boolean {
    return !!this.state?.checkoutRequirementsState && this.state?.checkoutRequirementsState?.offerBundleId === offerBundleId;
  }

  clearState(): void {
    this.initState(initialState);
  }
}
