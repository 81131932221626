import { Injectable } from "@angular/core";
import { BrokerStatus, SubmissionDetailsViews } from "../../../../shared/SharedConstants";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SubmissionDetailsService {
  constructor(private router: Router) { }

  goToSubmissionDetails(id: number, brokerStatus: BrokerStatus) {
    const expectedDetailsView = this.mapBrokerStatusToSubmissionDetailsView(brokerStatus);
    const baseUrl = `/submission/${id}`;
    const url = expectedDetailsView === SubmissionDetailsViews.Refactored ? baseUrl : `${baseUrl}/${expectedDetailsView}`;
    return this.navigateTo(url);
  }

  isDetailsViewCorrectForBrokerStatus(brokerStatus: BrokerStatus, providedDetailsView?: SubmissionDetailsViews) {
    const expectedDetailsView = this.mapBrokerStatusToSubmissionDetailsView(brokerStatus);
    return (!providedDetailsView && expectedDetailsView === SubmissionDetailsViews.Refactored) || providedDetailsView === expectedDetailsView;
  }

  private mapBrokerStatusToSubmissionDetailsView(brokerStatus: BrokerStatus): SubmissionDetailsViews {
    switch (brokerStatus) {
      case BrokerStatus.PROCESSING:
      case BrokerStatus.NOT_INTERESTED:
      case BrokerStatus.DECLINED:
      case BrokerStatus.DORMANT: {
        return SubmissionDetailsViews.Refactored;
      }
      case BrokerStatus.OPEN:
      case BrokerStatus.FUNDED: {
        return SubmissionDetailsViews.Refactored;
      }
      case BrokerStatus.ABANDONED:
      case BrokerStatus.CLOSED: {
        return SubmissionDetailsViews.Refactored;
      }
      case BrokerStatus.CONTRACT_READY:
      case BrokerStatus.CONTRACT_OUT: {
        return SubmissionDetailsViews.Refactored;
      }
      case BrokerStatus.DRAFT:
      case BrokerStatus.SUBMISSION_INCOMPLETE: {
        return SubmissionDetailsViews.SubmissionForm;
      }
      case BrokerStatus.OFFER:
      case BrokerStatus.CONDITIONAL_OFFER: {
        return SubmissionDetailsViews.OfferDetails;
      }
      case BrokerStatus.CLOSING:
      case BrokerStatus.CLOSING_INCOMPLETE: {
        return SubmissionDetailsViews.Refactored;
      }
    }
  }

  private navigateTo(url: string) {
    void this.router.navigate([url]);
  }
}
