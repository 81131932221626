<mat-dialog-content>
  <h2 class="heading">Generate email offer</h2>
  <p class="description">{{data.accountName}} has been approved for funding from {{data.companyName}}. Generate an email offer to the address below. To add more emails, separate each email address with a semicolon.</p>
  <form [formGroup]="form">
    <mat-form-field class="form-field"
                    appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput
             formControlName="email"
             focus="true"
             type="text">
    </mat-form-field>
  </form>
</mat-dialog-content>
<div class="actions">
  <button mat-button
          [mat-dialog-close]="false">CANCEL</button>
  <a [href]="mailTo"
     mat-button
     [mat-dialog-close]="true"
     color="primary">GENERATE EMAIL</a>
</div>
