import { Component, Input, OnChanges } from '@angular/core';
import { ClosingDetailsFacade } from './closing-details.facade';
import { ClosingDetailsActionType, ClosingDetailsComponentData } from './closing-details.model';
import { SubmissionDetailsParams } from '../../submission-details.model';
import { SubmissionDetailsActionsService } from '../../actions/submission-details-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadCheckoutRequirementDocumentData, RemoveCheckoutRequirementDocumentData, SaveCheckoutRequirementDocumentData } from '../../../../domain/models/submissions.model';
import { AppBarAction, AppPageService } from 'common';
import { tap } from 'rxjs/operators';
import { OfferRequirementsAdapter } from '../../shared/modules/offer-requirements-section/offer-requirements-section.data';
import { OfferDetailsAdapter } from '../../shared/modules/offer-details-section/offer-details-section.data';

@UntilDestroy()
@Component({
  selector: 'ifbp-closing-details',
  templateUrl: './closing-details.component.html',
  styleUrls: ['./closing-details.component.scss'],
  providers: [ClosingDetailsFacade, OfferRequirementsAdapter, OfferDetailsAdapter]
})
export class ClosingDetailsComponent implements OnChanges {
  viewData: ClosingDetailsComponentData;
  isFirstInit: boolean = true;
  actionInProgress: boolean;

  @Input()
  params: SubmissionDetailsParams;

  constructor(
    private readonly facade: ClosingDetailsFacade,
    private readonly actions: SubmissionDetailsActionsService,
    private readonly appPageService: AppPageService
  ) { }

  ngOnChanges(): void {
    this.initData();
    this.initActions();
  }

  private initData() {
    return this.facade.getComponentData$(this.params.appId).pipe(untilDestroyed(this))
      .subscribe(data => {
        this.viewData = data;
        this.actions.markClosingDocumentsSectionAsInvalid(false);
        if (this.isFirstInit) {
          this.firstInitActions(data);
          this.isFirstInit = false;
        }
      });
  }

  private firstInitActions(data: ClosingDetailsComponentData) {
    this.facade.openIncompleteSubmissionDialogIfNeeded(data.application);
    this.actions.markDataAsLoaded(true);
  }

  private initActions() {
    this.actions.removeCheckoutRequirementDocument$.pipe(untilDestroyed(this))
      .subscribe((data: RemoveCheckoutRequirementDocumentData) => {
        this.facade.removeCheckoutRequirementDocument(<number>this.viewData.selectedOfferBundleId, data);
      })

    this.actions.downloadCheckoutRequirementDocument$.pipe(untilDestroyed(this))
      .subscribe((data: DownloadCheckoutRequirementDocumentData) => {
        this.facade.downloadCheckoutRequirementDocument(data);
      })

    this.actions.saveCheckoutRequirementDocuments$.pipe(untilDestroyed(this))
      .subscribe((data: SaveCheckoutRequirementDocumentData[]) => {
        this.facade.saveCheckoutRequirementDocuments(<number>this.viewData.selectedOfferBundleId, data)
      })
  }

  onAppBarActionInvoke(action: AppBarAction, appId: number): void {
    if (this.actionInProgress) {
      return;
    }
    if (action.id !== ClosingDetailsActionType.SubmitForReview) {
      return;
    }

    this.actionInProgress = true;
    this.submitForReview(appId);
  }

  private submitForReview(appId: number) {
    if (!this.viewData.offerRequirementsData?.isDataValid) {
      this.actions.markClosingDocumentsSectionAsInvalid(true);
      return;
    }
    this.facade.submitForReview(appId, this.viewData.advisorData)
      .pipe(tap(() => this.handleSuccess())).subscribe();
  }

  private handleSuccess() {
    this.appPageService.back();
    this.actionInProgress = false;
  }
}
