import { SubmissionDetailsHeaderData } from "../../submission-details.model";
import { BrokerStatus } from "projects/partner/src/app/shared/SharedConstants";
import { getContactString } from "../../submission-details.data";
import { AdvisorData } from "../../../../shared/models/advisor.model";
import { ApplicationData } from "../../../../domain/models/application.model";
import { SubmissionDetailsBannerData } from "../../shared/components/submission-details-banner-section/submission-details-banner-section.model";
import { AppBarAction, ButtonColor, DialogActionData } from "common";
import { ClosingDetailsActionType } from "./closing-details.model";

export const getClosingDetailsHeaderData = (brokerStatus: BrokerStatus, advisorData: AdvisorData): SubmissionDetailsHeaderData | undefined => {
  const contactString = getContactString(advisorData);

  switch (brokerStatus) {
    case BrokerStatus.CLOSING:
      return getClosingStatusDetailsHeaderData(contactString);

    case BrokerStatus.CLOSING_INCOMPLETE:
      return getClosingIncompleteDetailsHeaderData(contactString);

    default:
      return undefined;
  }
}

const getClosingStatusDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Submission in Review",
    subtitle: `We are currently reviewing your submission and will reach out to you shortly. ${contactString}`,
  }
}

const getClosingIncompleteDetailsHeaderData = (contactString: string): SubmissionDetailsHeaderData => {
  return {
    title: "Complete your submission",
    subtitle: `Provide the required information below so we can move forward with this submission. ${contactString}`,
  }
}

export const getSubmissionDetailsBannerData = (application: ApplicationData): SubmissionDetailsBannerData | undefined => {
  if (application.brokerStatus !== BrokerStatus.CLOSING_INCOMPLETE) {
    return undefined;
  }


  if (!application.incompleteInfo) {
    return undefined;
  }

  return {
    title: "Missing information",
    description: `${application.incompleteInfo}`,
  }
}

export const getIncompleteSubmissionDialogData = (incompleteInfo?: string): DialogActionData => {
  const incompleteInfoString = incompleteInfo ? `<br><br>${incompleteInfo}` : '';
  return {
    body: `Provide the required information below so we can move forward with this submission.${incompleteInfoString}`,
    title: "Complete your submission",
    buttons: [
      {
        label: "OK",
        buttonColor: ButtonColor.Primary,
        action: true,
      }
    ]
  }
}

export const getClosingDetailsAppBarActions = (brokerStatus: BrokerStatus): AppBarAction[] => {
  if (brokerStatus === BrokerStatus.CLOSING_INCOMPLETE) {
    return [{
      id: ClosingDetailsActionType.SubmitForReview,
      label: "SUBMIT FOR REVIEW",
      buttonType: "button",
      buttonAppearance: "flat",
      buttonColor: "primary",
    }];
  }
  return [];
}

export const getSubmitConfirmationDialogData = (advisorData: AdvisorData): DialogActionData => {
  const contactString = getContactString(advisorData);

  return {
    body: `We are currently reviewing the Closing Documents and will reach out to you shortly. ${contactString}`,
    title: "Submitted for review",
    buttons: [
      {
        label: "OK",
        buttonColor: ButtonColor.Primary,
        action: true,
      }
    ]
  }
}
