import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BrokerStatus } from 'projects/partner/src/app/shared/SharedConstants';
import { OfferRequirementsSectionData } from './offer-requirements-section.model';
import { SubmissionDetailsActionsService } from '../../../actions/submission-details-actions.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ifbp-offer-requirements-section',
  templateUrl: './offer-requirements-section.component.html',
  styleUrls: ['./offer-requirements-section.component.scss']
})
export class OfferRequirementsSectionComponent implements OnInit, OnChanges {
  readonly BrokerStatus = BrokerStatus;
  isDataAvailable: boolean;
  isClosingDocsRequirementsAvailable: boolean;
  isNextStepsRequirementsAvailable: boolean;
  isClosingDocsRequirementsMarkedAsInvalid: boolean;

  @Input()
  data: OfferRequirementsSectionData;

  constructor(private readonly actions: SubmissionDetailsActionsService) { }

  ngOnInit(): void {
    this.actions.markClosingDocumentsSectionAsInvalid$.pipe(untilDestroyed(this))
      .subscribe((isInvalid: boolean) => {
        this.isClosingDocsRequirementsMarkedAsInvalid = isInvalid;
      })
  }
  ngOnChanges(): void {
    this.isClosingDocsRequirementsAvailable = !!this.data?.requirements?.closingDocuments?.length;
    this.isNextStepsRequirementsAvailable = !!this.data?.requirements?.nextSteps?.length;
    this.isDataAvailable = this.data?.brokerStatus && (this.isClosingDocsRequirementsAvailable || this.isNextStepsRequirementsAvailable);
  }
}
